import { Form, FormInstance, FormItemProps, Select } from "antd";
import React, { useMemo } from "react";
import "src/components/07.inputs/styles.scss";
import { getCurrentPattern } from "src/constants/patern";
import CustomInput from "src/pages/productManagement/CustomInput/CustomInput";
import "./styles.scss";

export const PREFIXES = [
  { value: "+65", label: "+65" },
  { value: "+84", label: "+84" },
];

interface Props {
  name: string;
  isRequired: boolean;
  formInstance: FormInstance;
  rules?: FormItemProps["rules"];
  label?: React.ReactNode;
  onChangeRegion?: (value: string) => void;
  onChange?: any;
  style: any;
}

const CustomPhoneInput = React.forwardRef((props: Props) => {
  const {
    name,
    rules,
    formInstance,
    label,
    onChangeRegion,
    isRequired,
    ...inputProps
  } = props;

  const region = Form.useWatch("phoneCode", formInstance);

  const phonePattern = useMemo(() => getCurrentPattern(region), [region]);

  return (
    <div className="phone-input-custom">
      <CustomInput
        name={name}
        label={label}
        rules={[
          {
            required: isRequired,
            message: "This field is required",
          },
          {
            pattern: phonePattern,
            message: "Invalid phone number",
          },
        ]}
        {...inputProps}
        prefix={
          <Form.Item name="phoneCode" initialValue={PREFIXES[0].value}>
            <Form.Item name="phoneCode">
              <Select
                defaultValue={PREFIXES[0].value}
                className="custom-select"
                style={{ border: "none" }}
                onClick={(e) => e.stopPropagation()}
                onChange={() => {
                  onChangeRegion && onChangeRegion("phoneCode");
                  formInstance.validateFields(["phone"]);
                }} // validate phone number when change region
                options={PREFIXES}
                showSearch={false}
              />
            </Form.Item>
          </Form.Item>
        }
      />
    </div>
  );
});

export default CustomPhoneInput;
